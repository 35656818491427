import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SideBySide from "../components/SideBySide"
import { graphql } from "gatsby"
import FeatureList from "../components/FeatureList.js"

const sideBySideFirstRow = [
  {
    name: "Single Zone",
    description:
      "Through advanced technology, Inverter air conditioners are more economical to operate and quieter to run than conventional units reaching the desired temperature more quickly than conventional air conditioners. The Inverter component allows the outdoor unit to vary its speed and output to match the required capacity of the indoor unit. Thus, the Inverter model can achieve 30+% more operating efficiency than conventional models and therefore, is much less expensive to run.",
  },
  {
    name: "ENERGY STAR® Qualified",
    description:
      "Since mini splits have little to no ducting, they forego those energy losses typically associated with central forced-air systems. Duct losses can easily account for more than 30% of energy consumption, especially if the ducts are not sealed tight or in an unconditioned space such as an attic or crawlspace. With ratings as high as 33.0-SEER (Seasonal Energy Efficiency Ratio), these systems are among the highest rated efficiency equipment available anywhere!",
  },
  {
    name: "Clean Air",
    description:
      "These heat pumps feature both an Apple-Catechin Filter and an Ion Deodorizing Filter. The Apple-Catechin Filter absorbs dust, mold spores and microorganisms by static electricity. Growth is inhibited and deactivated by the polyphenol ingredient extracted from apples. The Ion Deodorizing Filter deodorizes by powerfully decomposing absorbed odors using the oxidizing and reducing effects of ions generated by the ultra-fine particle ceramic.",
  },
  {
    name: "Quiet Operation",
    description: `Wall mounted systems include an extra quiet fan speed ("Quiet Mode") to make sure that you are not disturbed.`,
  },
]

const sideBySideSecondRow = [
  {
    name: "Inverter Technology",
    description:
      "Through new, advanced technology, Inverter air conditioners are more economical to operate and quieter to run than conventional units. They can handle greater extremes in temperature, are smoother and more stable in operation, and reach the desired temperature faster than conventional air conditioners.",
  },
  {
    name: "Inverter Control",
    description:
      "The Inverter component allows the outdoor unit to vary its speed and output to match the required capacity of the indoor unit. Thus, the Inverter model can achieve 30% more operating efficiency than conventional models and therefore, is much less expensive to run.",
  },
  {
    name: "Stable and Comfortable",
    description:
      "The air conditioner's output power is stabilized at the optimum setting within the range from maximum to minimum to match the load, which is affected by factors such as the room temperature and the number of people present.",
  },
]

const features = [
  "Apple Catechin Filter",
  "Ion Deodorizing Filter",
  "Sleep Timer",
  "24-Hour Timer",
  "Dry Mode",
  "Auto Louver-Up/Down",
  "Auto Louver: 4-way",
  "Auto Mode",
  "Minimum Heat Mode",
  "Quiet Mode",
  "Auto Restart/Reset",
  "Auto Changeover",
  "Low Ambient",
  "Power Diffuser",
]

const Fujitsu = ({ data }) => {
  return (
    <Layout>
      <Seo title="Fujitsu" />
      <SideBySide
        image={data.row1Image}
        items={sideBySideFirstRow}
        header="Fujitsu Mini Split Heat Pump"
        imgRight
        subheader="Sleek units require no ductwork and allow for room-by-room temperature control"
      />
      <SideBySide
        image={data.row2Image}
        items={sideBySideSecondRow}
        header="Multi-Zone"
        subheader="Each indoor unit can be operated independently. That is, on/off, temperature, air movement, up/down (wall mount unit only), time clock operation, can all be controlled from their own remote."
      />
      <FeatureList
        features={features}
        header="Other Standard Features Include"
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query FujitsuPageQuery {
    row1Image: file(
      relativePath: { eq: "img-decor-options-wall-mounted.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    row2Image: file(relativePath: { eq: "fp-image.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`

export default Fujitsu
