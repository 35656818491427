import React from "react"
import { CheckIcon } from "@heroicons/react/outline"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function FeatureList(props) {
  const middle = Math.ceil(props.features.length / 2)
  return (
    <div className="bg-light">
      <div className="px-4 py-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <h2 className="text-2xl text-center font-extrabold tracking-tight text-blue sm:text-3xl">
          {props.header}
        </h2>
        <div className="pt-8 xl:grid xl:grid-cols-2 xl:gap-x-8">
          <div className="mt-4 md:grid md:grid-cols-2 md:gap-x-8 xl:mt-0 xl:col-span-2">
            <ul className="divide-y divide-gray-200">
              {props.features.slice(0, middle).map((feature, featureIdx) => (
                <li
                  key={feature}
                  className={classNames(
                    featureIdx === 0 ? "md:py-0 md:pb-4" : "",
                    "py-4 flex"
                  )}
                >
                  <CheckIcon
                    className="flex-shrink-0 w-6 h-6 text-red"
                    aria-hidden="true"
                  />
                  <span className="ml-3 text-base text-gray-500">
                    {feature}
                  </span>
                </li>
              ))}
            </ul>
            <ul className="border-t border-gray-200 divide-y divide-gray-200 md:border-t-0">
              {props.features.slice(middle).map((feature, featureIdx) => (
                <li
                  key={feature}
                  className={classNames(
                    featureIdx === 0 ? "md:border-t-0 md:py-0 md:pb-4" : "",
                    "py-4 flex"
                  )}
                >
                  <CheckIcon
                    className="flex-shrink-0 w-6 h-6 text-red"
                    aria-hidden="true"
                  />
                  <span className="ml-3 text-base text-gray-500">
                    {feature}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
